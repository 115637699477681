$themes: (
    diva-cloud-light: (
        diva-blue: #379be5,
        diva-light-blue: #b9dcf7,
        diva-green: #00acc1,
        diva-indigo-blue: #5c6bc0,
        diva-orange: #eb672c,
        diva-pink: #e658a1,
        diva-violet: #ab47bc,
        diva-yellow: #fdd835,
        diva-lime-green: #5cc100,
        diva-error-red: #d0021b,
        diva-light-green: #d0f3f7,
        diva-light-indigo-blue: #cce5f4,
        diva-light-orange: #ffe7dd,
        diva-light-pink: #ffe3f1,
        diva-light-violet: #f6d4fc,
        diva-light-yellow: #fff8db,
        diva-light-lime-green: #defbc3,
        diva-medium-gray: #d8d8d8,
        diva-dark-gray: #CCCCCC,
        diva-navy: #443f5f,
        diva-dark-blue: #205a85,
        diva-black: #141720,
        diva-light-black: #2d313d,
        diva-bg-light-blue: #e2eff9,
        diva-light-gray: #f5f5f5,
        diva-white: #ffffff,
    ),
);

@mixin themify($themes) {
    @each $theme, $map in $themes {
        :host-context(.theme-#{$theme}) & {
            $theme-map: () !global;
            @each $key, $submap in $map {
                $value: map-get(map-get($themes, $theme), "#{$key}");
                $theme-map: map-merge(
                    $theme-map,
                    (
                        $key: $value,
                    )
                ) !global;
            }
            @content;
            $theme-map: null !global;
        }
    }
}

@mixin themifyNoHostContext($themes) {
    @each $theme, $map in $themes {
        .theme-#{$theme} & {
            $theme-map: () !global;
            @each $key, $submap in $map {
                $value: map-get(map-get($themes, $theme), "#{$key}");
                $theme-map: map-merge(
                    $theme-map,
                    (
                        $key: $value,
                    )
                ) !global;
            }
            @content;
            $theme-map: null !global;
        }
    }
}

@function themed($key) {
    @return map-get($theme-map, $key);
}

// to override the "overflow: hidden" property of all mat-icon
@mixin mat-icon-overflow {

    @include themifyNoHostContext($themes) {
      overflow: visible;
    }
  }

@mixin regular-button {
    @include themify($themes) {
        height: 40px;
        line-height: 40px;
        font-weight: 700;
        background-color: themed("diva-blue");
        border-radius: 4px;
    }
}


@mixin regular-button-disabled {
    @include themify($themes) {
        height: 40px;
        line-height: 40px;
        font-weight: 700;
        background-color: themed("diva-dark-gray");
        color: themed("diva-black");
        opacity: 0.5;
    }
}

@mixin regular-button-outline {
    @include themify($themes) {
        height: 40px;
        line-height: 40px;
        font-weight: 700;
        border-color: themed("diva-blue");
        border-radius: 4px;
        border-width: 1px;
        color: themed("diva-blue");
    }
}

@mixin delete-button {
    @include themify($themes) {
        height: 40px;
        line-height: 40px;
        font-weight: 700;
        background-color: themed("diva-error-red");
        border-radius: 4px;
    }
}

@mixin cancel-button {
    @include themify($themes) {
        height: 40px;
        line-height: 40px;
        font-weight: 700;
        border-color: themed("diva-navy");
        border-radius: 4px;
        border-width: 1px;
        color: themed("diva-navy");
    }
}

@mixin snakbar-error {
    @include themifyNoHostContext($themes) {
        margin: 24px;

        .mdc-snackbar__surface {
          background-color: themed("diva-error-red");
          padding-right: 0px;
        }
        .mat-mdc-snack-bar-label {
          width: 360px;
          padding: 14px 16px;
        }

        .snack-msg {
            color: themed("diva-white");
        }

        .snack-icon {
            color: themed("diva-white");
        }

        .snack-close {
            color: themed("diva-white");
        }
    }
}

@mixin snakbar-success {
    @include themifyNoHostContext($themes) {
        margin: 24px;

        .mdc-snackbar__surface {
          background-color: themed("diva-white");
          padding-right: 0px;
        }
        .mat-mdc-snack-bar-label {
          width: 360px;
          padding: 14px 16px;
        }

        .snack-msg {
            color: themed("diva-black");
        }

        .snack-icon {
            color: themed("diva-lime-green");
        }

        .snack-close {
            color: themed("diva-black");
        }
    }
}


@mixin regular-button-conf {
  @include themifyNoHostContext($themes) {
    height: 40px;
    line-height: 40px;
    font-weight: 700;
    background-color: themed("diva-blue");
    border-radius: 4px;
    margin: 5px;
  }
}
