//START Typography-Section: To use custom typography this section should be used before custom theme to apply on all material components.
@use '@angular/material' as mat;
@use 'diva-cloud-theme' as diva;

$custom-typography: mat.define-typography-config(
  $font-family: '"Open Sans", "Noto Sans JP"',
  $headline-6: mat.define-typography-level(
    $font-size: 16px,
    $line-height: 32px,
    $letter-spacing: normal,
    $font-weight: 500
  ),
  $subtitle-1: mat.define-typography-level(
    $font-size: 16px,
    $line-height: normal,
    $letter-spacing: normal
  ),
  $subtitle-2: mat.define-typography-level(
    $font-size: 14px,
    $line-height: normal,
    $letter-spacing: normal
  ),
  $body-1: mat.define-typography-level(
    $font-size: 14px,
    $line-height: normal,
    $letter-spacing: normal
  ),
  $body-2: mat.define-typography-level(
    $font-size: 14px,
    $line-height: normal,
    $letter-spacing: normal
  ),
  $caption: mat.define-typography-level(
    $font-size: 12px,
    $line-height: normal,
    $letter-spacing: normal
  ),
  $button: mat.define-typography-level(
    $font-size: 14px,
    $letter-spacing: normal
  )
);
$my-app-theme: mat.define-light-theme((
  typography: $custom-typography
));
@include mat.core();
@include mat.all-component-themes($my-app-theme);
//END Typography-Section:

//Set default font family
* {
  font-family: "Open Sans", "Noto Sans JP";
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-size: 14px;
}

h1,
h2,
h3 {
  margin: 0;
  font-weight: 600;
  font-size: 16px !important;
  margin-bottom: 16px;
}

mat-form-field {
  // when a form has invalid value
  &.ng-invalid {
    .mat-mdc-form-field-required-marker {
      color: #f44336;
    }
  }

  // when a form which has valid value is focused
  &:not(.ng-invalid).mat-focused {
    .mat-mdc-form-field-required-marker {
      color: #ffd740;
    }
  }

  .mat-mdc-text-field-wrapper {
    padding: 0px;
    background-color: transparent !important;

    .mdc-line-ripple::after {
      border-bottom-color: transparent;
    }

    .mat-mdc-form-field-focus-overlay {
      background-color: transparent;
    }

    // when a not-disabled form has a valid value
    &:not(.mdc-text-field--invalid):not(.mdc-text-field--disabled) {
      .mdc-floating-label {
        color: #707070;
      }
      .mdc-text-field__input {
        color: #575757;
      }
      .mdc-line-ripple::before {
        border-bottom-color: #707070;
      }
    }

    // when a not-disabled form has invalid value
    &.mdc-text-field--invalid:not(.mdc-text-field--disabled) {
      mat-label {
        color: #f44336;
      }
      .mdc-line-ripple::before {
        border-bottom-color:#f44336;
      }
    }

    // For disabled form
    &.mdc-text-field--disabled {
      .mat-mdc-form-field-required-marker::after {
        // To remove require marker from a disabled form
        color: transparent;
      }
    }
  }

  // To remove unexpected space
  .mat-mdc-form-field-bottom-align::before {
    display: inline;
  }
}

// to override the "overflow: hidden" property of all mat-icon
.mat-icon {
  @include diva.mat-icon-overflow;
}

.mat-drawer-content {
  margin-left: 80px;
}

.mat-drawer-opened {
  + .mat-drawer-content {
    margin-left: 290px;
  }
}

.mat-mdc-raised-button:not([class*="mat-elevation-z"]) {
  box-shadow: none;
}

/*Custom css for components generated*/
.myPanelClass {
  margin: 25px 0px;
}

.snakbar-error {
  @include diva.snakbar-error;
}

.snakbar-success {
  @include diva.snakbar-success;
}

.mat-mdc-input-element {
  height: 18px;
}

.pannelClassForConfirm {
  &.app-confirm-dialog {
    width: fit-content;

    .mat-mdc-dialog-title {
      padding: 0 20px;
      height: 58px;
    }

    .messagepart {
      justify-content: center;
      line-height: 24px;
    }

    .mat-mdc-dialog-actions {
      display: flex;
      flex-direction: row;
      justify-content: center;

      button {
        width: fit-content;
        &.mat-primary {
          @include diva.regular-button-conf;
        }
      }

      a {
        color: rgba(0, 0, 0, 0.87);
        text-decoration: none;
        cursor: pointer;
        margin: 5px;
        border: 1px solid #443f5f;
        width: 131px;
        height: 38px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.pannelClassForConfirmRevokeToken {
  &.app-confirm-dialog {
    min-width: unset !important;
    width: 402px;

    .mat-mdc-dialog-title {
      padding: 0 20px;
      height: 58px;
    }

    .messagepart {
      line-height: 24px;
    }

    .mat-mdc-dialog-actions {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;

      button {
        width: 133px;

        &.mat-primary {
          @include diva.regular-button-conf;
        }
      }

      a {
        color: rgba(0, 0, 0, 0.87);
        text-decoration: none;
        cursor: pointer;
        font-weight: bold;
        margin: 5px;
        border: 1px solid #443f5f;
        width: 131px;
        height: 38px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

#global-spinner {
  position: absolute;
  top: 0;
  z-index: 1;
}

.list-loading-spinner {
  order: 0;
  flex: 0 1 auto;
  align-self: auto;
}

.list-loading-spinner circle {
  stroke: #379BE5;
}
